import { OutboundLink } from 'gatsby-plugin-google-gtag';
import React from 'react';
import styled from 'styled-components';

import Cite from '../components/Cite';
import Layout from '../components/Layout';
import Text from '../components/Text';

const ListItem = styled.li`
  margin: 3rem 0;
`;

export default function ProjectsPage() {
  return (
    <Layout title="Projects">
      <Text>
        <small>Some things I've been involved with recently..</small>
      </Text>

      <ul>
        <ListItem>
          <Text>
            <span role="img" aria-label="speech bubble emoji">
              💬
            </span>{' '}
            Developed forum software which is being used to help small
            businesses find support during the COVID-19 pandemic
          </Text>
        </ListItem>

        <ListItem>
          <Text>
            <span role="img" aria-label="headphones emoji">
              🎧
            </span>{' '}
            <OutboundLink href="https://earworms.club">Earworms</OutboundLink>{' '}
            is a micro social media site I built as a side project during the
            first UK lockdown. You can share your favourite track of the week,
            follow your friends and discover new music
          </Text>
        </ListItem>

        <ListItem>
          <Text>
            <span role="img" aria-label="bar charts emoji">
              📊
            </span>{' '}
            Built data visualisations with <Cite>Who Targets Me</Cite> during
            the UK 2019 General Election, one of which was shown during the{' '}
            <Cite>BBC News at Ten</Cite> the night before polling day
          </Text>
        </ListItem>

        <ListItem>
          <Text>
            <span role="img" aria-label="magnifying glass emoji">
              🔍
            </span>{' '}
            Led the{' '}
            <a href="https://twitter.com/serendipity_ai/status/941600004980051968">
              front end development
            </a>{' '}
            of interactive data exploration tools while working side by side
            with AI and machine learning engineers
          </Text>
        </ListItem>

        <ListItem>
          <Text>
            <span role="img" aria-label="light bulb emoji">
              💡
            </span>{' '}
            Took a{' '}
            <a href="https://apps.apple.com/gb/app/bulb/id1225129939">
              mobile app
            </a>{' '}
            from an idea to the App Store for a renewable energy startup
          </Text>
        </ListItem>

        <ListItem>
          <Text>
            <span role="img" aria-label="love heart emoji">
              ❤️
            </span>{' '}
            Built the first <Cite>Time Out Love London Awards</Cite> web app,
            which was{' '}
            <a href="https://twitter.com/sfrostwick/status/646800937063948289">
              promoted by the then Mayor of London
            </a>{' '}
            (he's now the Prime Minister)
          </Text>
        </ListItem>

        <ListItem>
          <Text>
            <span role="img" aria-label="">
              🎄
            </span>{' '}
            Competed in,{' '}
            <a href="https://twitter.com/sfrostwick/status/447059957792243712">
              and won
            </a>
            , the first hackathon at one of the UK's largest retailers (they
            went on to build a full version of the idea for the Christmas
            trading period)
          </Text>
        </ListItem>
      </ul>
    </Layout>
  );
}
